export default {
  name: 'KioskForm',
  model: {
    prop: 'form',
    event: 'change'
  },
  props: {
    name: {
      type: String,
      default: undefined
    },
    tag: {
      type: String,
      value: 'VRow'
    },
    form: {
      type: Object,
      required: true
    }
  },
  computed: {
    fields() {
      return this.form.fields
    }
  },
  render(h) {
    const genError = () => {
      if (!this.form.state.error) {
        return null
      }

      if (this.$scopedSlots.error) {
        return this.$scopedSlots.error(this.form)
      }

      return this.$slots.error
    }

    const genSubmitted = () => {
      if (!this.form.state.submitted) {
        return null
      }

      if (this.$scopedSlots.submitted) {
        return this.$scopedSlots.submitted(this.form)
      }

      return this.$slots.submitted
    }

    const genForm = () => {
      if (this.$scopedSlots.default) {
        return this.$scopedSlots.default(this.form)
      }

      return null
    }

    const genOthers = () => {
      const { error, submitted, default: def, ...others } = this.$scopedSlots
      return Object.values(others).map((scopedSlots) => {
        return scopedSlots(this.form)
      })
    }

    return h(
      'VForm',
      {
        props: this.$attrs,
        on: {
          submitted: this.$listeners['submitted'] || (() => {}),
          submit: (event) => {
            event.preventDefault()
            this.form.submit()
          }
        }
      },
      [genError(), genSubmitted(), ...genOthers(), genForm()]
    )
  }
}
