<script>
export default {
  name: 'KioskFormField',
  inheritAttrs: false,
  model: {
    prop: 'field',
    event: 'change'
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      default() {
        return this.field.name
      }
    }
  },
  render(h) {
    return this.field.render(h, this)
  }
}
</script>
