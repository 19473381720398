<template>
  <VCard
    color="cardPrimary"
    flat
    style="
    width: 100vw;
    height: 80vh;"
  >
    <VContainer>
      <VRow>
        <VCol cols="12" class="pa-0 pl-3 pt-4">
          <VCardText
            class="pa-0 headline font-weight-medium"
            v-text="$t('app.kiosk.forms.adminSettings.settings.text')"
          />
          <VCardText
            class="pa-0 title font-weight-light"
            v-text="$t('app.kiosk.forms.adminSettings.settings.subtext')"
          />
        </VCol>
        <VCol cols="12" class="pa-0">
          <KioskSettingPresetForm @submitted="onInstallSettingFormSubmitted" />
        </VCol>
      </VRow>
    </VContainer>
  </VCard>
</template>

<script>
import KioskSettingPresetForm from './components/KioskInstallSettingPresetForm'

export default {
  name: 'InstallSetting',
  components: {
    KioskSettingPresetForm
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'install.done') {
      this.$store.commit('kiosk/SET_PERMISSIONS', [])
    }

    next()
  },
  props: {
    redirectOnSubmitted: {
      type: Object,
      default() {
        return { name: 'install.done' }
      }
    }
  },
  methods: {
    onInstallSettingFormSubmitted(submitOk) {
      if (submitOk) {
        this.$router.push(this.redirectOnSubmitted)
      }
    }
  }
}
</script>
