<template>
  <KioskAdminSettingForm v-bind="$attrs" v-on="$listeners">
    <template #actions="form">
      <VBtn
        type="submit"
        color="primary"
        class="v-btn-custom"
        data-heap-id="field_submit"
        :loading="form.state.loading"
        :disabled="form.$v.$invalid"
      >
        <div v-t="'app.kiosk.forms.install.submit.btn.text'" />
        <VIcon right v-text="'arrow_forward'" />
      </VBtn>
    </template>
  </KioskAdminSettingForm>
</template>

<script>
import KioskAdminSettingForm from '@/views/admin/components/KioskAdminSettingForm'

export default {
  name: 'KioskInstallSettingPresetForm',
  components: {
    KioskAdminSettingForm
  },
  inheritAttrs: false
}
</script>
