<template>
  <KioskForm v-model="form" app>
    <template #submitted="submittedProps">
      <slot name="submitted" v-bind="submittedProps" />
    </template>
    <template v-if="uninstall" #uninstall>
      <slot name="uninstall" v-bind="{ form, setUninstall }" />
    </template>
    <template #default>
      <VContainer grid-list-md>
        <VRow>
          <VCol cols="12" :sm="8" mb-3>
            <slot name="error" />
          </VCol>
          <slot name="title" />
          <VCol cols="12" :sm="8">
            <KioskFormField
              v-model="form.fields.langs"
              :items="langItems"
              :menu-props="{ auto: true }"
              attach
              chips
              multiple
              outline
              filled
              :mandatory="true"
              class="v-input-custom"
              @input="onLangChange"
            />
          </VCol>
          <VCol cols="12" :sm="8">
            <KioskFormField
              v-model="form.fields.langDefault"
              :items="langDefaultItems"
              :mandatory="true"
              :menu-props="{ auto: true }"
              attach
              chips
              outline
              filled
              class="v-input-custom"
              @input="setGlobalLocale"
            />
          </VCol>
          <VCol cols="12" :sm="8">
            <KioskFormField
              v-model="form.fields.loginType"
              :items="loginTypeItems"
              :mandatory="true"
              :menu-props="{ auto: true }"
              attach
              outline
              filled
              class="v-input-custom"
            />
          </VCol>
          <VCol cols="12" :sm="8">
            <KioskFormField
              v-model="form.fields.phoneNumber"
              :mandatory="true"
              outline
              filled
              return-masked-value
              class="v-input-custom"
            />
          </VCol>
          <VCol cols="12" :sm="'auto'" ml-5>
            <slot name="actions" v-bind="form">
              <VBtn v-show="false" large type="submit" />
            </slot>
          </VCol>
          <VCol v-if="displayUninstall" cols="8">
            <VBtn
              class="ma-0"
              text
              color="grey"
              data-heap-id="field_uninstall"
              @click="() => setUninstall(true)"
            >
              <VIcon class="black--text">delete</VIcon>
              <div
                v-t="'app.kiosk.forms.adminSettings.uninstall.btn.text'"
                class="black--text body-1 text-none"
              />
            </VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </template>
  </KioskForm>
</template>

<script>
import { FormBuilder, SETTING_LOCALES, SETTING_LOGIN_TYPES } from '@/lib/kiosk'
import KioskForm from '@/components/KioskForm'
import KioskFormField from '@/components/KioskFormField'
import { required } from '@/validators'

export default {
  name: 'KioskAdminSettingForm',
  components: {
    KioskForm,
    KioskFormField
  },
  props: {
    formData: {
      type: Function,
      default() {
        const setting = this.$store.getters['kiosk/setting']
        return { ...setting }
      }
    },
    submit: {
      type: Function,
      async default(formState) {
        await this.$store.dispatch('kiosk/saveSetting', this.form.data)
        const { langDefault } = this.$store.getters['kiosk/setting']
        this.$i18n.locale = langDefault
      }
    }
  },
  data() {
    return {
      form: null,
      fields: this.formData(),
      uninstall: false
    }
  },
  computed: {
    displayUninstall() {
      return !!this.$scopedSlots.uninstall
    },
    langItems() {
      return SETTING_LOCALES.map((localeKey) => {
        return {
          value: localeKey,
          text: this.$t(`app.kiosk.langs.${localeKey}.default`)
        }
      })
    },

    langDefaultItems() {
      return this.fields.langs.map((localeKey) => {
        return {
          value: localeKey,
          text: this.$t(`app.kiosk.langs.${localeKey}.default`)
        }
      })
    },

    loginTypeItems() {
      return [
        {
          value: SETTING_LOGIN_TYPES.PIN,
          text: this.$t('app.kiosk.pin')
        },
        {
          value: SETTING_LOGIN_TYPES.CREDENTIALS,
          text: this.$t('app.kiosk.credentials')
        }
      ]
    }
  },
  created() {
    this.form = FormBuilder.fromComponent(this)
      .setFields((as) => ({
        langs: as.component('VSelect'),
        langDefault: as.component('VSelect'),
        loginType: as.component('VSelect'),
        phoneNumber: as.component('VTextField')
      }))
      .setSubmitHandler((formState) => this.submit(formState))
      .build('adminSettings')
  },
  methods: {
    setUninstall(uninstallValue) {
      this.uninstall = uninstallValue
    },
    setFormStateFromLangValues(langValues) {
      const langDefault = this.form.fields.langDefault.value

      if (langValues.length === 0) {
        this.form.fields.langs.value = [langDefault]
      }

      if (!this.form.fields.langs.value.includes(langDefault)) {
        this.form.fields.langDefault.value = [
          ...this.form.fields.langs.value
        ].shift()

        this.setGlobalLocale()
      }
    },
    onLangChange(langValues) {
      this.setFormStateFromLangValues(langValues)
    },

    setGlobalLocale() {
      this.$i18n.locale = this.form.fields.langDefault.value
    }
  },
  validations() {
    return {
      fields: {
        langs: {
          required
        },
        langDefault: {
          required
        },
        loginType: {
          required
        },
        phoneNumber: {
          required
        }
      }
    }
  }
}
</script>
